<template>
  <div>
    <div class="header">
      <div class="imgs" @click="goback" >
        <!-- <img src="../../assets/mobile/arrow.png" alt @click="goback" /> -->
        <van-icon name="arrow-left" color="white"/>
      </div>
      <div class="title">合作咨询</div>
    </div>
    <div class="box">
      <div class="name" style="font-size: 0.95rem;">
        *您的姓名
        <div>
          <input type="text"  v-model="form.name" placeholder="您的姓名" style="padding-left: 0.625rem;font-size: 0.85rem;"/>
        </div>
      </div>
      <div class="mobile" style="font-size: 0.95rem;">
        *您的电话
        <div>
          <input type="text"  v-model="form.mobile" placeholder="您的电话" style="padding-left: 0.625rem;font-size: 0.85rem;"/>
        </div>
      </div>
      <div>
        <div style="font-size:  0.95rem;">*意向合作区域</div>
        <div>
      <van-field v-model="form.locationCityArray" is-link readonly placeholder="您的意向合作区域" class="locationCity"
        @click="show = true" style="margin-top: 10px;padding-left: 0.625rem;font-size: 0.85rem;"/>
      <van-popup v-model="show" round position="bottom">
        <van-cascader v-model="cascaderValue" title="您的意向合作区域" :options="baseCitys" :field-names="optionProps"
          @close="show = false" @finish="onFinish" active-color="blue"/>
      </van-popup>
    </div>
    </div>
    <div style="font-size: 12px;color: #b2b2b2;line-height:1.3 !important;padding-top: 5px;margin-bottom: 14px;">
    </div>
      <div class="msg" style="font-size: 0.95rem;">
        *您想咨询的内容
        <div>
           <textarea class="texts"  v-model="form.consult" placeholder="您想咨询的内容" style="padding-left: 0.625rem;font-size: 0.85rem;padding-top: 5px;"></textarea>
        </div>
      </div>
      <div class="submit" @click="submits">立即提交</div>
    </div>

  </div>
</template>
<script>
import { submit,queryCityTree } from "../../network/gobal";
import Vue from 'vue';
import { Toast, Field, Popup, Cascader,Icon } from "vant";
Vue.use(Cascader);
Vue.use(Field);
Vue.use(Popup);

Vue.use(Icon);
Vue.use(Toast);
export default {
  data() {
    return {
      show: false,
      cascaderValue: "",
      baseCitys: [],
      form: {
        name: "",
        mobile: "",
        consult: "",
        locationCityArray: [],
        locationCity: "",
      },
      optionProps: {
        text: "name",
        value: "name",
        children: "districts",
      },
    };
  },
  created(){
    this.getAllCity();
  },
  methods:{
    goback(){
      this.$router.go(-1)
    },
    submits() {

      const ruleName = /^(?:[\u4e00-\u9fa5]+)(?:·[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/;
      const rulePhone = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
     
      if (!this.form.name) {
        Toast("请输入您的姓名");
      } else if (!ruleName.test(this.form.name)) {
        Toast("请输入正确的姓名");
      } else if (!this.form.mobile) {
        Toast("请输入您的电话号码");
      } else if (!rulePhone.test(this.form.mobile)) {
        Toast("请输入正确的电话号码");
      } else if (
        !this.form.locationCityArray ||
        this.form.locationCityArray.length === 0
      ) {
        Toast("请选择您的意向合作区域");
      }
      else if (!this.form.consult) {
       Toast("请输入您想咨询的内容");
      } else if (this.form.consult.length>250) {
        Toast("咨询的内容最长不能超过250个字符");
      }
      else {
        this.form.locationCity = this.form.locationCityArray;
        submit(this.form).then(res => {
          if (res.code == 200) {
            Toast('提交成功!\n快进商店工作人员将尽快与您取得联系。');
            this.form = {
              name: "",
              mobile: "",
              consult: "",
              locationCityArray: [],
              locationCity: ""
            };
           this.$router.go(-1)
          } else {
            Toast(res.msg);
          }
        });
      }
    },

        //获取地区树
        getAllCity() {
      queryCityTree().then((res) => {
        if (res.code == 200) {
          // this.baseCitys = this.getTreeData(res.data)
          this.baseCitys = this.getTreeData(res.data);
        }
      });
    },
    //递归数据进行处理
    getTreeData(data) {
      for (var i = 0; i < data.length > 0; i++) {
        if (data[i].districts == null || data[i].districts.length <= 0) {
          // districts若为空数组，则将districts设为undefined
          data[i].districts = undefined;
        } else {
          // 如果 districts 的 name 与当前对象的 name 相同，跳过该子分支(不需要这个子分支，直接设为 undefined)
          if (data[i].districts[0].name === data[i].name) {
            data[i].districts = undefined;
          } else {
            //districts若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].districts);
          }
        }
      }
      return data;
    },

    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.form.locationCityArray = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
  }
};
</script>
<style lang="scss" scoped>
::-webkit-input-placeholder {
  color: #cccccc;
  font-family: opposr, sans-serif;
  letter-spacing: 1px;

}
::-webkit-textarea-placeholder {
  color: #cccccc;
  font-family: opposr, sans-serif;
  letter-spacing: 1px; 
    font-size: 0.95rem;

}
.header {
  height: 55px;
  width: 100%;
  background: #1f10e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;

  .imgs {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: 1rem;
    font-family: opposm, sans-serif;
    font-weight: 500;
    color: #ffffff;
  }
}
textarea{
  padding-left: 5px;
  margin-top: 10px;
  width: 100%;
box-sizing: border-box;
  height: 6.25rem;
  white-space:pre-wrap;
 white-space:-moz-pre-wrap;
 white-space:-o-pre-wrap;
 word-wrap:break-word;
 border: 1px solid #B0B5BB;
border-radius: 3px;
-webkit-appearance:none;/*去除阴影边框*/
}
input{
 padding-left: 5px;
  width: 100%;
box-sizing: border-box;
  height: 2.3125rem;
  border: 1px solid #B0B5BB;
border-radius: 3px;
margin: 10px 0;
-webkit-appearance:none;/*去除阴影边框*/
}
.box {
  padding: 0 8%;
  font-size: 0.95rem;
  font-family: opposm, sans-serif;
  font-weight: 400;
  color: #333333;
}
.name {
  margin-top: 5.625rem;
  margin-bottom: 5px;;
  font-size:  0.85rem;
}
.mobile{
  margin-bottom: 5px;
  font-size:  0.85rem;
}
.submit{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.7rem;
  background: #1F10E0;
  box-shadow: 0px 3px 6px 0px rgba(127, 121, 255, 0.47);
  border-radius: 3px;
 
  font-size: 1rem;
  letter-spacing: 2px;

  color: #FFFFFF;
  margin-top: 2rem;
}
.locationCity{
  padding-left: 5px;
  height: 2.6rem;
  border: 1px solid #B0B5BB;
  border-radius: 3px;
  // margin: 10px 0;
  -webkit-appearance:none;/*去除阴影边框*/
  padding-top: 0.5rem;
  font-size:  0.85rem;

}

::v-deep .locationCity ::placeholder{
  color: #CCCCCC;
    font-family: opposr,sans-serif;
    letter-spacing: 1px;
    font-size: 0.85rem;
}

.msg{
  border-radius: 3px;
  font-size:  0.85rem;
  
}

</style>
