<template>
  <div class="aboutUs">
    <img src="../assets/aboutUs/banner.svg" alt style="width:100%" />
    <div class="bg" style=" margin-top: -5px;">
      <div class="aboutTitle">关于我们</div>
      <div class="aboutTitle2">ABOUT&nbsp;US</div>
      <div class="line"></div>
      <div class="texts">
        <div
          class="text"
          style="letter-spacing:1px;"
        >快进时代（厦门）科技有限公司成立于2021年，总部位于福建省厦门市。旗下产品快进商店，致力于通过自主研发的全套智能硬件+SaaS平台技术解决方案，直击线下门店“熬夜守店累、请人成本贵”等经营痛点，解决守店之困，轻松实现24小时营业，不浪费一分钟房租。在有效为门店提升销售业绩的同时，降低人工成本，提升竞争力。</div>
        <div
          class="text"
          style="margin-top:25px;letter-spacing:1px"
        >作为一家敢想敢为的科技创业公司，快进商店核心团队有着超过10年的智能零售产品研发及运营经验，是一支兼具大量实践经验和前瞻性思维的复合型人才团队。公司先后获得数十项技术专利及软件著作权，并获得国家“创新型中小企业”及“高新技术企业”认定。在业务高速发展的同时，快进商店也频频获得资本青睐，成为行业内唯一连续获得三轮国内外知名风险投资机构投资的企业，包含头部产业投资方及纳斯达克上市公司。</div>
      </div>
    </div>
    <div class="bg2">
      <div class="kjTitle">
        <div class="line"></div>
        <div class="kjfxtitle">
          <div class="t1">快进方向</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="t2">DIRCTION</div>
      <div class="kjfx">
        <div v-for="item in iconList" :key="item.name">
          <div>
            <img
              :src="item.url"
              alt
              style="width:6.5rem;height:6.5rem"
              @mouseover="mouseOver(item)"
              @mouseleave="mouseLeave(item)"
            />
          </div>
          <div style="color:#42474D;letter-spacing: 2px;margin-top:5px">{{item.name}}</div>
        </div>
      </div>
    </div>

    <div class="bg3">
      <div class="bg3Box">
        <div class="title">快进理念</div>
        <div class="title2">VISION</div>
        <div class="line"></div>
        <div class="text">作为零售科技企业，我们要让快进商店的产品，更有温度，更接地气，覆盖所有目标群体。同时联动上下游合作伙伴，共同探索打通智能零售全链路的发展模式。</div>
      </div>
    </div>
    <div class="bg4">
      <div class="bg4Box">
        <div class="title">快进目标</div>
        <div class="title2">OBJECTIVE</div>
        <div class="line"></div>
        <div class="text">让合作商户通过最全面、最具性价比的创新方案，真正享受到科技带来的便利性，帮助百万门店实现经营突破，挖掘商业潜能，是快进商店核心的业务目标与价值体现。</div>
      </div>
    </div>

    <div class="environment">
        <div class="centon">
            
            <div style="width:100%;height:1px"></div>
            <div class="bgTitle">
              <div>
                <img src="../assets/home/left.png" alt />
              </div>
              <div style="margin:0.5rem 1.25rem 0">办公环境</div>
              <div>
                <img src="../assets/home/right.png" alt />
              </div>
            </div>
            <div class="img-box">
                <div class="item-box1">
                  <img src="../assets/aboutUs/descImg1.png">
                  <img src="../assets/aboutUs/descImg4.png">
                </div>
                <div class="item-box2">
                     <img src="../assets/aboutUs/descImg2.png">
                     <img src="../assets/aboutUs/descImg3.png">
                     <img src="../assets/aboutUs/descImg5.png">
                </div>
               <div class="item-box3">
                   <div>
                     <img  src="../assets/aboutUs/descImg6.png">
                   </div>
                   <div>
                      <img src="../assets/aboutUs/descImg7.png">
                      <img src="../assets/aboutUs/descImg8.png">
                      <div class="ColorBox"></div>
                   </div>
                </div>
                   
            </div>
        </div>
    </div>

    <gobalFooter></gobalFooter>
  </div>
</template>
<script>
// @ is an alias to /src
import gobalFooter from "../components/gobalFooter";
export default {
  components: { gobalFooter },
  data() {
    return {
      iconList: [
        {
          name: "创造可持续社会价值",
          url: require("../assets/aboutUs/icon1.png"),
          imgUrl: require("../assets/aboutUs/icon1.png"),
          gifUrl: require("../assets/aboutUs/icon1.gif"),
          index: 0
        },
        {
          name: "推动智能化技术和设施应用",
          url: require("../assets/aboutUs/icon2.png"),
          imgUrl: require("../assets/aboutUs/icon2.png"),
          gifUrl: require("../assets/aboutUs/icon2.gif"),
          index: 0
        },
        {
          name: "赋能传统产业数字化转型",
          url: require("../assets/aboutUs/icon3.png"),
          imgUrl: require("../assets/aboutUs/icon3.png"),
          gifUrl: require("../assets/aboutUs/icon3.gif"),
          index: 0
        },
        {
          name: "为奋斗者提供舞台",
          url: require("../assets/aboutUs/icon4.png"),
          imgUrl: require("../assets/aboutUs/icon4.png"),
          gifUrl: require("../assets/aboutUs/icon4.gif"),
          index: 0
        }
      ]
    };
  },
  mounted() {

  },
  methods: {
    mouseOver(item) {
      item.url = item.gifUrl;

      setTimeout(() => {
        item.url = item.imgUrl;
      }, 1000);
    },
    mouseLeave(item) {
      // item.url = item.imgUrl;
      // item.index = 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  font-family: opposr,sans-serif;
  margin-top: 68px;
}
@keyframes change {
  from {
    width: 53%;
    line-height: 30px;
    font-size: 1.0625rem;

    font-weight: 400;
    color: #4e4d5e;
    margin: 3.5rem auto 0;
  }
  to {
    width: 53%;
    line-height: 30px;
    font-size: 1.0625rem;

    font-weight: 400;
    color: #4e4d5e;
    margin: 2.5rem auto 0;
  }
}
.bg {
  width: 100%;

  height: 34rem;

  background: url("../assets/aboutUs/bg.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  padding-top: 8rem;
  .aboutTitle {
    letter-spacing: 2px;
    font-size: 3rem;
   font-family: opposm,sans-serif;
    font-weight: 500;
    color: #4e4d5e;
    margin-left: 23%;
  }
  .aboutTitle2 {
    margin: 0.5rem;
    font-size: 1.3125rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #b2b2b2;

    opacity: 0.2;
    margin-left: 23%;
  }
  .line {
    width: 76.5%;
    height: 1px;
    background-color: #1f10e0;
    margin: 2rem 0;
  }
  .texts {
    margin: 10rem auto 0;
    width: 50%;
    animation: change 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    font-weight: 400;
    color: #4e4d5e;
    text-align: justify;
    .text {

      font-size: 1.25rem !important;
    }
  }
}
.bg2 {
  width: 100%;

  height: 26rem;

  background: url("../assets/aboutUs/bg1.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  padding-top: 5.5rem;
  .t2 {
    margin-top: 0.5rem;
    width: 100%;
    text-align: center;
    font-size: 1.3125rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #d3d3d3;
  }
  .kjTitle {
    margin: 0 auto;
    width: 69%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    .line {
      width: 38%;
      height: 2px;
      background-color: #b2b2b2;
    }
    .t1 {
      font-size: 3rem;
      font-family: opposm,sans-serif;
      font-weight: 500;
      color: #4e4d5e;
      margin-bottom: -7px;
    }
  }
  .kjfx {
    width: 72%;
    position: absolute;
    bottom: 7rem;
    display: flex;
    justify-content: space-around;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-size: 1.25rem;

    font-weight: 500;
    color: #4e4d5e;
  }
}
.bg3 {
  margin: 6.25rem auto 3.125rem;
  width: 67%;
  height: 24.4375rem;
  background: url("../assets/aboutUs/bg3.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 11.5rem;
  letter-spacing: 1px;
  .bg3Box {
    width: 67%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    margin-bottom: 0.625rem;
    font-size: 3rem;
    font-family: opposm,sans-serif;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 3px;
  }
  .title2 {
    font-size: 1.25rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #ffffff;

    opacity: 0.2;
  }
  .line {
    margin: 1.8rem auto 1.8rem;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .text {
    letter-spacing: 3px;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    font-size: 1.25rem;

    font-weight: 400;
    color: #ffffff;
    line-height: 1.8rem;
  }
}
.bg4 {
  margin: 0 auto 6.25rem;
  width: 67%;
  height: 24.4375rem;
  background: url("../assets/aboutUs/bg4.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 6.25rem;
  letter-spacing: 1px;
  .bg4Box {
    width: 67%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    margin-bottom: 0.625rem;
    font-size: 3rem;
    font-family: opposm,sans-serif;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 3px;
  }
  .title2 {
    font-size: 1.25rem;
    font-family: opposm,sans-serif;
    font-weight: 800;
    color: #ffffff;

    opacity: 0.2;
  }
  .line {
    margin: 1.8rem auto 1.8rem;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  .text {
    letter-spacing: 3px;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    font-size: 1.25rem;

    font-weight: 400;
    color: #ffffff;
    line-height: 1.8rem;
  }
}
.environment {
   position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65.125rem;
  background: url("../assets/aboutUs/environment.png") no-repeat;
  background-size:cover ;
  .centon {
   
    img:hover {
      box-shadow: 0px 0px 10px rgba($color: #333333, $alpha: 0.3) ;
    }
    
    width: 67%;
    .bgTitle {
      margin-top: 6.625rem;
      display: flex;
      font-size: 1.75rem;
      justify-content: center;
      font-weight: 550;
      color: #3b3b72;
      letter-spacing: 2px;
      font-family: opposm,sans-serif;
      img {
        width: 28.875rem;
        height: 3px;
      }
    }
    .img-box {
      z-index: 999;
      margin-top: 4.875rem;
      display: flex;
     
      .item-box1 {
        margin-right: .8125rem;
        width: 30.75rem;
        img {
          width: 30.75rem;
          height: 20.5rem;
          &:nth-child(2) {
            margin-top: .875rem;
          }
        }
      }
      .item-box2 {
        z-index: 999;
        margin-right: .8125rem;
        width: 20.125rem;
        img {
          width: 20.125rem;
          height: 13.25rem;
          margin-bottom: .75rem;
          &:nth-child(3) {
            margin-bottom: 0px;
          }
        }
      }
      .item-box3 {
        z-index: 999;
        width: 30.75rem;
        div {
          width: 100%;
          &:nth-child(1){
             margin-bottom: 5px;
             img {
              width: 28.75rem;
              height: 20.5rem;
            }
          }
          &:nth-child(2){
           
            position: relative;
            display: flex;
            justify-content: space-between;
            .ColorBox{
              position: absolute;
              right: -1.375rem;
              bottom: -1.125rem;
              width: 2.625rem;
              height: 2.625rem;
              border-radius: .3125rem;
              background-color: #ff3162;
            }
            img {
               z-index: 999;
              width: 13.875rem;
              height: 20.75rem;
            }
          }
          
        }
      }
    }
  }
}
</style>
