<template>
  <div class="footer">
    <div class="ask">合作咨询</div>
    <div class="ipt">
      <input type="text" placeholder="您的姓名" v-model="form.name"/>
    </div>
    <div class="ipt">
      <input type="text" placeholder="您的电话" v-model="form.mobile" />
    </div>
    <span>
      <van-field v-model="form.locationCityArray" is-link readonly placeholder="您的意向合作区域" class="locationCity"
        @click="show = true" style="margin-top: 10px;"/>
      <van-popup v-model="show" round position="bottom">
        <van-cascader v-model="cascaderValue" title="您的意向合作区域" :options="baseCitys" :field-names="optionProps"
          @close="show = false" @finish="onFinish" active-color="blue"/>
      </van-popup>
    </span >
    <div style="font-size: 12px;color: #b2b2b2;line-height:1.3 !important;">
    </div>
    <div class="ipt">
      <textarea type="text" class="textwb" placeholder="您想咨询的内容" v-model="form.consult"/>
    </div>
    <div class="submit" @click="submits">提&nbsp;&nbsp;&nbsp;交</div>
    <div class="bottomMsg">
      <div class="logo-box" :style="{ 'margin-top': isBaidu ? '0' : '2.375rem' }">
        <img src="../../assets/gobalFoot/logo.svg" alt style="width: 4.6875rem; height: 5.625rem" />
      </div>
      <div class="mediaResource">
        <div class="name">快进时代（厦门）科技有限公司</div>
        <div class="phone">
          <div style="margin-top: 3px">
            <img src="../../assets/gobalFoot/phone.svg" alt style="width: 12px; height: 12px" />
          </div>
          <div style="margin-left: 5px">0592-5095708</div>
        </div>
        <div class="letter">
          <div style="margin-top: 5px">
            <img src="../../assets/gobalFoot/letter.svg" alt style="width: 12px; height: 12px" />
          </div>

          <div style="margin-left: 5px">service@ikuaijin.com</div>
        </div>

        <div class="Official" v-if="!isBaidu">
          <el-popover placement="top" width="50" trigger="hover" popper-class="my-popover">
            <div class="view" slot="reference">
              <div>
                <img src="@/assets/gobalFoot/Official.svg" />
              </div>
              <div>
                <span>官方视频号</span>
              </div>
              <div>微信扫一扫</div>
            </div>
            <img src="@/assets/gobalFoot/Official.svg" />
          </el-popover>

          <el-popover placement="top" width="50" trigger="hover" popper-class="my-popover">
            <div slot="reference" class="view">
              <div>
                <img src="@/assets/gobalFoot/wxvideo.svg" />
              </div>
              <div>
                <span>官方抖音号</span>
              </div>
              <div>抖音扫一扫</div>
            </div>
            <img src="@/assets/gobalFoot/wxvideo.svg" />
          </el-popover>
        </div>

        <div class="forRecord">
          <div class="title">
            <span @click="routeGo('/mHome')" :style="{ fontFamily: $route.path == '/mHome' ? 'opposb' : '' }">首页</span>
            <span>|</span>
            <span
              @click="routeGo('/mproduct')"
              :style="{
                fontFamily: $route.path == '/mproduct' ? 'opposb' : '',
              }"
              >门店改造</span
            >
            <span>|</span>
            <span @click="routeGo('/mCooperation')" :style="{
        fontFamily: $route.path == '/mCooperation' ? 'opposb' : '',
      }">渠道合作</span>
            <span>|</span>
            <span @click="routeGo('/mDevplatform')" :style="{
        fontFamily: $route.path == '/mDevplatform' ? 'opposb' : '',
      }">开放平台</span>
            <span>|</span>
            <span @click="routeGo('/mAboutUs')" :style="{
        fontFamily: $route.path == '/mAboutUs' ? 'opposb' : '',
      }">关于我们</span>
          </div>
          <div class="box1">增值电信业务经营许可证 闽B2-20230828</div>
          <div class="box2">闽ICP备2021012124号</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { submit, queryCityTree } from "../../network/gobal";
import Vue from "vue";
import { Toast, Field, Popup, Cascader } from "vant";
Vue.use(Cascader);
Vue.use(Field);
Vue.use(Popup);
export default {
  data() {
    return {
      show: false,
      cascaderValue: "",
      form: {
        name: "",
        mobile: "",
        consult: "",
        locationCityArray: [],
        locationCity: "",
      },

      optionProps: {
        text: "name",
        value: "name",
        children: "districts",
      },
      baseCitys: [],
      isBaidu: 0,
    };
  },
  created() {
    this.isBaidu = window.localStorage.getItem("isBaidu");
    this.getAllCity();
  },
  methods: {
    submits() {
      const ruleName =
        /^(?:[\u4e00-\u9fa5]+)(?:·[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/;
      const rulePhone =
        /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;

       if (!this.form.name) {
        Toast("请输入您的姓名");
      } else if (!ruleName.test(this.form.name)) {
        Toast("请输入正确的姓名");
      } else if (!this.form.mobile) {
        Toast("请输入您的电话号码");
      } else if (!rulePhone.test(this.form.mobile)) {
        Toast("请输入正确的电话号码");
      } else if (
        !this.form.locationCityArray ||
        this.form.locationCityArray.length === 0
      ) {
        Toast("请选择您的意向合作区域");
      }
      else if (!this.form.consult) {
        Toast("请输入您想咨询的内容");
      } else if (this.form.consult.length > 250) {
        Toast("咨询的内容最长不能超过250个字符");
      } else {
        this.form.locationCity = this.form.locationCityArray;
        submit(this.form).then((res) => {
          if (res.code == 200) {
            Toast("提交成功!\n快进商店工作人员将尽快与您取得联系。");
            this.cascaderValue= "",
              this.form = {
                name: "",
                mobile: "",
                consult: "",
                locationCityArray: [],
                locationCity: ""
              };
          } else {
            Toast(res.msg);
          }
        });
      }
    },
    routeGo(path) {
      if (this.$route.path == path) {
        scrollTo(0, 0);
      } else {
        this.$router.push(path);
      }
    },
    //获取地区树
    getAllCity() {
      queryCityTree().then((res) => {
        if (res.code == 200) {
          // this.baseCitys = this.getTreeData(res.data)
          this.baseCitys = this.getTreeData(res.data);
        }
      });
    },
    //递归数据进行处理
    getTreeData(data) {
      for (var i = 0; i < data.length > 0; i++) {
        if (data[i].districts == null || data[i].districts.length <= 0) {
          // districts若为空数组，则将districts设为undefined
          data[i].districts = undefined;
        } else {
          // 如果 districts 的 name 与当前对象的 name 相同，跳过该子分支(不需要这个子分支，直接设为 undefined)
          if (data[i].districts[0].name === data[i].name) {
            data[i].districts = undefined;
          } else {
            //districts若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].districts);
          }
        }
      }
      return data;
    },

    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.form.locationCityArray = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
  },
};
</script>
<style lang="scss">
.my-popover {
  padding: 0px;

  img {
    vertical-align: bottom;
  }
}
</style>
<style lang="scss" scoped>
.footer {
  background: #1f10e0;
  padding: 1.6875rem 2.2rem 5rem;
  font-family: opposr, sans-serif;

  .locationCity {
    width: 100%;
    font-size: 0.8rem;
    padding-left: 0.6475rem;
    height: 2.2625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    

    ::v-deep .van-field__control{
      color: black;
    }

    
  ::placeholder{
        color: #b2b2b2;
        font-size: 0.8rem;
         font-family: opposr,sans-serif;
         letter-spacing: 1px;
      }
  }

  ::placeholder {
    font-size: 0.8rem;
  }
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.ask {
  font-size: 0.93rem;
  font-family: opposm;
  font-weight: 500;
  color: #ffffff;
}

::v-deep .localShow .el-input__inner {
  overflow: hidden;
  padding: 1px 2px 1px 10px;
  font-size: 0.8rem;
  border-radius: 2px;
}

.ipt {
  font-size: 0.8rem;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  margin-top: 0.625rem;
}

input {
  width: calc(100% - 0.6875rem);
  height: 2.0625rem;
  border-radius: 1px;
  padding-left: 0.6875rem;
}

textarea {
  width: calc(100% - 0.6875rem);
  padding-left: 0.6875rem;
  padding-top: 0.3125rem;
  border: white !important;
  
}

.textwb {
  height: 67px;
  width: calc(100% - 0.6875rem);
  border-radius: 3px;
}

input::-webkit-input-placeholder {
  color: #b2b2b2;
  font-size: 0.8rem;
}

textarea::-webkit-input-placeholder {
  color: #b2b2b2 !important;
  font-family: opposr, sans-serif;
  letter-spacing: 1px;
   font-size: 0.8rem;
}

.submit {
  margin: 0.8375rem auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  height: 2.1875rem;
  background: #ff3162;
  border-radius: 1px;
  font-size: 0.9375rem;
  font-family: opposr, sans-serif;
  font-weight: bold;
  color: #ffffff;
  border-radius:3px ;
  letter-spacing: 2px;
}

.bottomMsg {
  position: relative;
  margin-top: 2.8125rem;
  display: flex;
  justify-content: space-between;
  margin-right: 0.375rem;

  // align-items: center
  .logo-box {}

  .mediaResource {}

  .Official {
    display: flex;
    align-items: center;

    .view {
      text-align: center;

      &:nth-child(1) {
        margin-right: 1.0625rem;
      }

      div {
        display: flex;
        justify-items: center;
        align-items: center;
      }

      img {
        // margin: 0px auto;
        // display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0px auto 0.25rem;
      }

      span,
      div {
        // margin: 0px auto;
        // display: inline-block;
        font-family: opposr;
        font-weight: 400;
        font-size: 0.5rem;
        color: #ffffff;
      }
    }
  }
}

::v-deep .van-cell{
  padding: 10px 10px 10px 16px;
}
.name {
  font-size: 0.7875rem;
  padding-bottom: 8px;
  font-weight: 500;
  color: #ffffff;
  border-bottom: 1px solid white;
  font-family: opposm, sans-serif;
}

.phone {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 0.7875rem;
  color: #ffffff;
  font-family: opposm, sans-serif;
}

.localShow {
  width: 100%;
  margin: 10px 0;

}

.letter {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  font-size: 0.7875rem;
  margin-top: 0;
  color: #ffffff;
  font-family: opposm, sans-serif;
}

.forRecord {
  position: absolute;
  bottom: -5rem;
  left: -2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 3rem;
  background: #3a1ffb;

  .title {
    text-align: center;
    width: 100%;

    span {
      font-size: 0.625rem;
      font-family: opposm, sans-serif;
      color: #ffffff;
      cursor: pointer;
    }

    span:nth-child(even) {
      margin: 0.25rem;
    }
  }

  .box2 {
    margin-bottom: 1.6vw;
    width: 100%;
    text-align: center;
    font-size: 0.5rem;
    color: #ffffff;
  }

  .box1 {
    // margin-top: 0;
    width: 100%;
    text-align: center;
    font-size: 0.5rem;
    color: #ffffff;
  }

  .custom-popper-class {
    max-width: 100%;
    /* 确保下拉框宽度不会超过父容器 */
    width: auto;
    /* 自动调整宽度以适应内容 */
    min-width: 200px;
    /* 可以设置最小宽度，避免下拉框太小 */
  }
}
</style>
