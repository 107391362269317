import {
  request,
  wxrequest
} from "./request";

//上传音频
export function submit(data) {
  return request({
    url: '/foreign/consult/add',
    method: "POST",

    data: data,
  })
}

//获取wxconfig信息
export function wxConfig(data) {
  return request({
    url: '/weixin/share',
    method: "POST"
  })
}


//获取地区
export function queryCityTree() {
  return request({
    url: '/foreign/getAllZoneOnChina',
    method: "GET",
  })
}
