<template>
  <div
    id="app"
    :class="{ 'mobile-device': !!flag }"
    @click.stop="close"
    @mouseover.stop="over"
    @mouseleave.stop="leave"
  >
    <img
      src="@/assets/shareIcon.png"
      ref="logoImg"
      style="
        width: 200px;
        height: 200px;
        position: absolute;
        left: -400px;
        top: -400px;
        opacity: 0;
      "
      alt=""
    />
    <navigationBar v-if="!flag"></navigationBar>
    <mNavigationBar v-else></mNavigationBar>
    <router-view style="" />
  </div>
  <!-- <div id="app"  >
    <navigationBar></navigationBar>
    <router-view />
  </div>-->
</template>
<script>
// @ is an alias to /src
import navigationBar from "@/components/navigationBar.vue";
import mNavigationBar from "./components/mobile/mNavigationBar";
import { config, wshare } from "./network/wxConfig";
import wx from "weixin-js-sdk";
export default {
  name: "Home",
  components: {
    navigationBar,
    mNavigationBar,
  },
  data() {
    return {
      flag: "",
      show: false,
    };
  },
  mounted() {
    this.rclick();
  },
  created() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.isBaidu()
    //  config()
    // if (this.$router.path !== "/") {
    //   this.$router.replace("/");
    // }
  },
  watch: {
    $route(to, from) {
      if (
        (to.path == "/mHome" ||
          to.path == "/mproduct" ||
          to.path == "/mCooperation" ||
          to.path == "/mAboutUs") &&
        this.isWeixin()
      ) {
        let logoImgUrl =
          "https://www.ikuaijin.com/" + require("@/assets/shareIcon.png");
        let link = "https://www.ikuaijin.com/mHome";
        // if (shanghuId) {
        //   link += "?shanghuId=" + shanghuId;
        // }
        console.log(logoImgUrl);
        config();

        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: [
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
              "hideAllNonBaseMenuItem",
              "showMenuItems",
              "scanQRCode",
              "updateAppMessageShareData",
              "updateTimelineShareData",
            ], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
            success: function (res) {
              console.log(res);
            },
          });
          wx.hideAllNonBaseMenuItem();
          wx.showMenuItems({
            menuList: ["menuItem:share:appMessage", "menuItem:share:timeline"], // 要显示的菜单项，所有menu项见附录3
          });
          var obj = {
            title: "快进商店KJ-Store",
            desc: "有快进，更亲近！数字化无人便利店新零售升级改造，夫妻店连锁便利店自助购物，智能超市24小时云值守。",
            imgUrl: logoImgUrl,
            link: link,
          };

          wshare(obj);
        });
        wx.error(function (res) {
          console.log(res, "@@@@@@");
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      }
    },
  },
  methods: {
    // 当前是否是微信环境
    isWeixin() {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    },
    isBaidu() {
      const urlParams = new URLSearchParams(window.location.search);
      const flag = urlParams.get("flag");
      if(flag==1){
        return window.localStorage.setItem('isBaidu',1)
      }else{
        return window.localStorage.removeItem('isBaidu')
      }
    },
    over() {
      document.oncontextmenu = function () {
        event.returnValue = false;
      };
    },
    leave() {
      document.oncontextmenu = function () {
        event.returnValue = false;
      };
    },
    stop() {},
    close() {
      this.$store.commit("changeShow", false);
    },
    rclick() {
      if (document.all) {
        if (event.button == 2) {
          event.returnvalue = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
html {
  font-size: 14px;

  @media screen and (min-width: 300px) and (max-width: 375px) {
    font-size: 16px;
  }

  @media screen and (min-width: 376px) and (max-width: 390px) {
    font-size: 17px;
  }

  @media screen and (min-width: 391px) and (max-width: 415px) {
    font-size: 18px;
  }

  @media screen and (min-width: 416px) and (max-width: 450px) {
    font-size: 19px;
  }

  @media screen and (min-width: 451px) and (max-width: 500px) {
    font-size: 20px;
  }

  @media screen and (min-width: 576px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 10px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1690px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1700px) and (max-width: 1900px) {
    font-size: 15px;
  }

  @media screen and (min-width: 1901px) {
    font-size: 16px;
  }
}
body {
  display: block;
  margin: 0px;
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  //  pointer-events:none;
  // overflow:hidden
}
input:focus {
  outline: none;
}
.el-carousel__indicators--horizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 2.5rem !important;
  text-align: center;
  z-index: 99999;
  display: flex;
  align-items: center;
  .el-carousel__indicator--horizontal button {
    margin: 0 5px;
    // width: 8px;
    // height: 8px;
    // background: #ffffff;
    // border-radius: 50%;
    // opacity: 0.5;
    width: 10px;
    height: 10px;
    background-color: #ffffff !important;
    z-index: 9999;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 12px;
    height: 12px;
    background-color: #ff3162 !important;
    // opacity: 0.5;
    // border-radius: 10px;
  }
}

input {
  border: 0px;
}

/*谷歌、safari、qq浏览器、360浏览器滚动条样式*/
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 1px;
  height: 6.875rem;
  background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
/*滑块效果*/
::-webkit-scrollbar-thumb:hover {
  border-radius: 0.3125rem;
  -webkit-box-shadow: inset 0 0 1px rgba(#1f10e0, 0.2);
  background: #f5f5f5;
}
/*IE滚动条颜色*/
html {
  scrollbar-face-color: #1f10e0; /*滚动条颜色*/
  scrollbar-highlight-color: #000;
  scrollbar-3dlight-color: #000;
  scrollbar-darkshadow-color: #000;
  scrollbar-shadow-color: #1f10e0; /*滑块边色*/
  scrollbar-arrow-color: rgba(0, 0, 0, 0.4); /*箭头颜色*/
  scrollbar-track-color: #1f10e0; /*背景颜色*/
}
</style>
