<template>
  <div class="home">
    <div  style='position: relative;'>
<swiper
      :slides-per-view="4"
      @swiper="onSwiper"
      :options="swiperOption"
      @slideChange="onSlideChange"
      ref="mySwiper"
      class="swiper"
    >
      <swiper-slide
        class="swItem"
        v-for="(item, index) in list"
        :key="index"
        :class="{'arrows':true}"
        :style="{backgroundImage: 'url(' + item.url + ')',height:'97vh',backgroundSize:'100% 100%' }"
      >
      <!-- <div class="left">
        <img src="../../assets/mobile/home/left.png" alt="" style="width:100%">
      </div>
      <div class="right">
        <img src="../../assets/mobile/home/right.png" alt="" style="width:100%">
      </div> -->
        <div class="bannerText">
          <div class="titles" :class="{'titles1':index==3}">
            <div class="bannerTitle">{{item.text1}}</div>
            <div class="bannerTitle">{{item.text2}}</div>
            <div class="bannerTitle">{{item.text3}}</div>
          </div>
          
          <div class="bannerRed"></div>
          <div class="msgs">
            <div class="bannerMsg">{{item.msg1}}</div>
            <div class="bannerMsg">{{item.msg2}}</div>
          </div>
          <div class="learnmore" v-if="index!=0">
            <span>了解更多 ></span>
          </div>
          <div class="learnmore0" v-if="index==0">
            <span>下滑查看更多</span>
            <!-- <img src="../../assets/mobile/home/select.png" alt="" srcset=""> -->
          </div>
        </div>
        <div class="bannerVideo">
          <video
            autoplay="autoplay"
            width="100%"
            height="97vh"
            v-if="item.isVideo"
            style="object-fit: fill;"
            loop
            muted
          >
            <source src="https://cdn.ikuaijin.com/kuaijin/www/www_m.mp4" type="video/mp4" />
          </video>
          <div class="bannerImg" v-if="index==0"></div>
        </div>
       
        <!-- <div class="bannerVideo" v-if="item.isVideo">
          <video
          controls
            style="width:100%;height:54rem;object-fit: fill;"
            id="myVideo"
            class="video-js"
            loop="loop"
          >
            <source
              src="https://cdn.ulandian.com/kuaijin/www/www_m.mp4"
              type="video/mp4"
            />
          </video>
          <div class="bannerImg"></div>
        </div>-->
      </swiper-slide>
       <div class="swiper-pagination" slot="pagination"></div>
      
      <!-- <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div> -->
    </swiper>
      <img
          onclick="scroll2()"
          class="index__down"
          src="../../assets/mobile/home/icon.png"
          alt="↓"
        />
    </div>
    

    <!-- <swiper ref="swiper" style="margin-top:-2px">
      <swiper-item
        class="swItem"
        v-for="(item, index) in list"
        :key="index"
        :style="{backgroundImage: 'url(' + item.url + ')',height:'720px',backgroundSize:'100% 100%' }"
      >
        <div class="bannerText">
          <div class="bannerTitle">{{item.text1}}</div>
          <div class="bannerTitle">{{item.text2}}</div>
          <div class="bannerTitle">{{item.text3}}</div>
          <div class="bannerRed"></div>
          <div class="bannerMsg">{{item.msg1}}</div>
          <div class="bannerMsg">{{item.msg2}}</div>
        </div>
        <video
          style="width:100%;height:53.875rem"
          id="myVideo"
          class="video-js"
          v-if="item.isVideo"
          loop="loop"
        >
          <source
            src="https://cdn.ulandian.com/kuaijin/www/lv_0_20211223154410.mp4"
            type="video/mp4"
          />
        </video>
        <img :src="item.text" alt class="bannerText" style="width:100%;height:100%" v-else />
      </swiper-item>
    </swiper>-->
    <div class="start">
      <div style="width:100%;height:1px"></div>
      <div>
        <div class="startTitle">
          解决商户
          <span
            style="font-size: 1.8rem;font-weight: 500;color: #1F10E0;font-family:opposm;margin-left:-0.225rem"
          >守店之痛</span>
          <div>助力门店降本增效</div>
        </div>

        <div class="startLine"></div>
        <div class="startText">快进商店为快进时代（厦门）科技有限公司旗下，专注于传统线下商户数字化、无人化升级改造的智能零售平台。</div>
        <div
          class="startText2"
        >快进商店通过自主研发的全套技术解决方案，运用智能硬件+SaaS平台的双向维度输出，帮助国内数百万家零售小店实现降本增收、时间自由；让广大消费者实现真正的“即时性”消费，每时每刻都能享受到快进商店带来的便捷服务。</div>
      </div>
    </div>
    <div class="end" ref="end">
      <div class="title" style="margin-top: 3.4375rem;">深耕行业数年</div>
      <div class="title">全套自研系统</div>
      <div class="title" style="margin-bottom:35px">技术实力保障</div>
      <div>
        <img src="../../assets/mobile/home/text.gif" alt style="width:100%;" v-show="isShow1==1" />
        <img src="../../assets/mobile/home/text.png" alt style="width:100%;" v-show="isShow1==2" />
      </div>
      <div class="submit" @click="openDialog">了解更多</div>
    </div>
    <div class="bg">
      <div style="width:100%;height:1px"></div>
      <div class="bgimg">
        <div
          v-for="(item,index) in mall"
          :key="item.url"
          :class="{'moveup':index==2||index==3}"
          style="width:50%"
          class="mall"
        >
          <img :src="item.url" alt />
           <div class="mallname">{{item.name}}</div>
        </div>
      </div>
      <div class="explain">快进商店产品适用于各类型便利店、零售店，科技赋能助力门店轻松经营</div>
    </div>
    <div class="bg2">
      <div class="bg2Title">快进商店五大服务保障</div>
      <div class="line">
        <img src="../../assets/mobile/home/line.png" alt />
      </div>
      <div class="iconList">
        <div
          v-for="(item,index) in icons"
          :key="item.url"
          class="iconItem"
          :class="{'shorts':index==1||index==3||index==6}"
        >
          <div v-if="item.url">
            <img :src="item.url" alt />
            <div class="item">
              <div>{{item.name}}</div>
              <div>{{item.text}}</div>
            </div>
          </div>

          <div class="line" v-else></div>
        </div>
      </div>
      <div class="iconLists">
        <div
          v-for="(item,index) in lists1"
          :key="item.url"
          class="iconItems"
          :class="{'shorts':index==1}"
        >
          <div v-if="item.url">
            <img :src="item.url" alt />
            <div class="item">
              <div>{{item.name}}</div>
              <div>{{item.text}}</div>
            </div>
          </div>

          <div class="line" v-else></div>
        </div>
      </div>
    </div>
    <mGobalFooter></mGobalFooter>
     
  </div>
</template>
<script>
let vm = null;
import Vue from "vue";
import mGobalFooter from "../../components/mobile/mGobalFooter";
import { Lazyload } from "vant";
import { Swiper, SwiperItem } from "../../components/swiper";

Vue.use(Lazyload);
const c_swiper = document.getElementsByClassName("swiper");
export default {
  components: {
    // Swiper,
    // SwiperItem,
    mGobalFooter
  },
  data() {
    return {
      isIndex:1,
      isShow1: 3,
      actindex: 1,
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
      
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        // autoplay: true,
        // 设置自动轮播
        autoplay: {
          autoplay: true,
          delay: 4000, // 5秒切换一次
          stopOnLastSlide: false, //当自动切换到最后一张时是否停止自动切换
          disableOnInteraction: true //用户触摸时是否停止自动切换
        },
        // 设置轮播可循环
        loop: true,
          pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true
        },
        on: {
          slideChange: () => {
            // if(c_swiper[0].swiper.activeIndex==1){
            //   this.swiperOption.autoplay.delay=18000
            // }else{
            //   this.swiperOption.autoplay.delay=5000
            // }
            vm.isIndex = c_swiper[0].swiper.activeIndex;
          },
          click:(swiper) =>{
            
            vm.clickGo(vm.isIndex)
          }
        }
      },
      lists: [
        {
          // url: "https://cdn.ulandian.com/kuaijin/www/www_m1.gif",
          url: require("../../assets/mobile/home/banner4.gif"),

          text: require("../../assets/home/bannerImg.png"),
          text1: "不想苦守门店",
          text2: "就找快进商店",
          msg1: "有人+智能模式随时切换",
          msg2: "24小时营业，降本增收"
        }
      ],
      list: [
        {
          // url: "https://cdn.ulandian.com/kuaijin/www/www_m1.gif",
          url: require("../../assets/mobile/home/banner0.png"),

          text: require("../../assets/home/bannerImg.png"),
          text1: "不想苦守门店",
          text2: "就找快进商店",
          msg1: "有人+智能模式随时切换",
          msg2: "24小时营业，降本增收"
        },
        {
          url: require("../../assets/mobile/home/banner1.jpg"),

          text1: "开店做生意",
          text2: "老板不用那么累",
          msg1: "千元成本，24小时内完成升级",
          msg2: "让经营超轻松"
        },
        {
          url: require("../../assets/mobile/home/banner2.png"),

          text1: "诚邀有志之士",
          text2: "共享时代红利",
          msg1: "智能化、数字化零售大势所趋",
          msg2: "把握先机，立即行动"
        },
        {
          url: require("../../assets/mobile/home/banner3.png"),

          text1: "心怀梦想",
          text2: "脚踏实地",
          text3: "快进有我",
          msg1: "十年连续创业团队",
          msg2: "生而骄傲只因不忘初心"
        }
      ],
      mall: [
        { url: require("../../assets/mobile/home/mall1.png"),name: "传统夫妻店"  },
        { url: require("../../assets/mobile/home/mall2.png"),name: "品牌加盟店"  },
        { url: require("../../assets/mobile/home/mall3.png") ,name: "连锁直营店"},
        { url: require("../../assets/mobile/home/mall4.png"),name: "药店等"  }
      ],
      icons: [
        {
          url: require("../../assets/mobile/home/icon1.svg"),
          name: "小程序、后台系统",
          text: "迭代升级永久免费"
        },
        { line: true },
        {
          url: require("../../assets/mobile/home/icon2.svg"),
          name: "品牌硬件全套质保",
          text: "24小时内服务响应"
        },
        { line: true },
        {
          url: require("../../assets/mobile/home/icon3.svg"),
          name: "Ai算法精准视觉识别",
          text: "黑名单系统风控保障"
        }
      ],
      lists1: [
        {
          url: require("../../assets/mobile/home/icon4.svg"),
          name: "一对一专属业务顾问",
          text: "全年365天总部客服"
        },
        { line: true },
        {
          url: require("../../assets/mobile/home/icon5.svg"),
          name: "值守期间商品缺失",
          text: "平台追回，售后无忧"
        }
      ]
    };
  },
  created(){
    vm = this;
    this.isShow1=3
  },
  computed: {},
  mounted() {
// 根据不同路由跳转不同页面
    if (!this._isMobile()) {

      this.$router.replace('/')
      return;
    }

    this.isElementInViewports(this.$refs.end);
    // this.initVideo();
    // var md = document.getElementsByTagName("video")[0];
    // if (md.ended) {
    //   console.log("结束");
    // }
    // md.addEventListener("ended", function() {
    //   console.log("结束");
    // });
    //  setTimeout(() => {

    //   this.swiperOption.autoplay.delay = 5000;
    //    console.log(this.swiperOption.autoplay.delay)
    // }, 18000);
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
     openDialog() {
      this.$router.push("/mDialogConsult");
    },
    isElementInViewports(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();
        
        if (rect.top < 750 && that.actindex == 1) {
        
           that.actindex = 2;
          that.isShow1 = 1;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            that.isShow1 = 2;
          }, 2850);
        }
      });
    },
    initVideo() {
      let that = this;
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: false,
        //自动播放属性,muted:静音播放
        autoplay: "muted",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",

        controlBar: {
          playToggle: false,
          currentTimeDisplay: false,
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          volumePanel: {
            inline: false
          }
        }
      });

      // myPlayer.on("ended", function() {
      //   // that.isBanner = false;
      //   that.interval = 3000;
      //   that.$refs.carousel.next();
      //   console.log("播放结束");
      // });
    },
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange(index) {
      // console.log(index,this.$refs.mySwiper);
    },
    clickGo(index){
      let arr = ["/mHome","/mHome","/mproduct","/mCooperation","/mAboutUs","/mHome"];
      if (index == 1 || index == 5) return;
      this.$router.push({
        path:arr[index]
      })  
    }
  }
};
</script>
<style>
/* .swiper-box >>>.swiper-pagination-bullet-active{
  background-color: red;
} */
.swiper-container-horizontal>.swiper-pagination-bullets{
  bottom: 12%;
    left: 0;
    width: 100%;
}
  .swiper-pagination-bullet{
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 100%;
    background: #ffffff !important;
    opacity: 1;
    
   }
   .swiper-pagination-bullet-active {
      background:#ff3162 !important;
       width: 7px;
    height: 7px;

   }
 
  

</style>
<style lang="scss" scoped>
.arrows{
  position: relative;
}
.left{
  position: absolute;
  width: 1.625rem;
  height: 3rem;
  left:3%;
  top:50%;
   transform: translate(0, -50%);
}
.right{
  position: absolute;
   width: 1.625rem;
  height: 3rem;
  right:3%;
  top:50%;
     transform: translate(0, -50%);
}
@-webkit-keyframes toggleMove {
  0% {
    bottom: 8%;
    opacity: 0.3;
  }

  50% {
    bottom: 7%;
    opacity: 1;
  }

  to {
    bottom: 8%;
    opacity: 0.3;
  }
}
.home {
  margin-top: 55px;
}
.index__down {
  position: absolute;
  bottom: 81px;
  width: 24px;
  height: 11px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: toggleMove 0.8s infinite;
  animation: toggleMove 0.8s infinite;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: toggleMove;
  z-index: 5;
  cursor: pointer;
}
.bannerVideo {
  width: 100%;
  height: 97vh;
}
.bannerImg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1F10E0 0%, rgba(31, 16, 224, 0.2) 100%);
  // opacity: 0.4;
}
.swItem {
  position: relative;
}
.bannerText {
  text-align: left;

  .titles {
    z-index: 99999;
    position: absolute;
    top: 10%;
    left: 10%;
  }
  .titles1 {
    position: absolute;
    top: 5%;
    z-index: 99999;
    left: 10%;
  }
  .bannerTitle {
    font-size: 2rem;
    font-family: opposb, sans-serif;
    z-index: 99999;
    color: #ffffff;
    margin-top: 0.1rem;
    // font-weight: bold;
  }
  .bannerRed {
    position: absolute;
    z-index: 99999;
    top: 22%;
    left: 10%;
    width: 2.5rem;
    height: 3px;
    background-color: #ff3162;
    margin: 1.5rem 0 1.5rem;
  }
  .msgs {
    z-index: 99999;
    position: absolute;
    top: 29%;
    left: 10%;
    font-family: opposb, sans-serif;
  }
  .bannerMsg {
    z-index: 99999;
    font-size: 1.0625rem;
    font-family: opposb, sans-serif;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0.4rem;
  }
  .learnmore {
    position: absolute;
    z-index: 99999;
    top: 39%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.875rem;
    height: 2.25rem;
    border: 1.5px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: .1875rem;
    font-size: .75rem;
    font-family: opposm;
  }
  .learnmore0{
    position: absolute;
    z-index: 99999;
    top: 38%;
    left: 10%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    // width: 4.875rem;
    height: 2.25rem;
    color: #FFFFFF;
    font-size: .85rem;
    font-family: opposm;
    text-align: center;

    span{
font-weight: 500;
color: #FFFFFF;
margin-bottom: 12px;
    }

    img{
      width: 18px;
      height: 10px;
    }
  }
}
.start {
  width: 100%;
  background: url("../../assets/mobile/home/start.png") no-repeat 0 2.1875rem;
  height: 35.3125rem;
  // padding-top: 10%;
  background-size: 100% auto;
  background-color: #f8f8f9;
}
.startTitle {
  margin-left: 14%;
  font-size: 1.65rem;
  font-family: opposm, sans-serif;
  font-weight: 500;
  color: #3b3b72;
  margin-top: 2.5rem;
}
.startLine {
  width: 86%;
  height: 1px;
  background-color: #1f10e0;
  margin: 1rem 0;
}
.startText {
  text-align: justify;
  margin: 0 auto;
  width: 72%;
  font-size: 0.875rem;
  font-family: opposr, sans-serif;
  font-weight: 400;
  color: #4e4d5e;
  line-height: 1.4rem;
}
.startText2 {
  margin: 1rem auto 0;
  width: 72%;
  text-align: justify;
  font-size: 0.875rem;
  font-family: opposr, sans-serif;
  font-weight: 400;
  color: #4e4d5e;
  line-height: 1.4rem;
}
.end {
  .title {
    text-align: center;
    font-size: 1.65rem;
    font-family: opposm;
    font-weight: 500;
    color: #3b3b72;
  }
  .submit {
    margin: 1.875rem auto 2.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #ffffff;
    width: 7.5rem;
    height: 2.25rem;
    background: #1f10e0;
    border-radius: 3px;
  }
}
.bg {
  width: 100%;
  background: url("../../assets/mobile/home/bg1.png") no-repeat;
  height: 30.5rem;
  background-size: 100% 100%;
  .moveup {
    margin-top: -4px;
  }
  .bgimg {
    margin: 2.1875rem auto 0;
    width: 76%;
    display: flex;
    flex-wrap: wrap;
    .mall{
      position: relative;
      .mallname{
        width: 100%;
        position: absolute;
        bottom: 10%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, 0);
        font-size: 0.75rem;
        font-family: opposm, sans-serif;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    img {
      width: 100%;
    }
  }
  .explain {
    width: 75%;
    font-size: 1.0625rem;
    font-family: opposm, sans-serif;
    text-align: center;
    font-weight: 400;
    color: #4e4d5e;
    margin: 0.5rem auto 0;
  }
}
.bg2 {
  .bg2Title {
    margin: 2.5625rem auto 0;
    text-align: center;
    letter-spacing: 1px;
    font-family: opposm, sans-serif;
    font-size: 1.65rem;

    font-weight: 500;
    color: #3b3b72;
  }
  .line {
    margin-top: -8px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .iconList {
    margin: 1.5rem auto 0;
    width: 92%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 0.6875rem;
    font-family: opposm, sans-serif;
    font-weight: 400;
    color: #4e4d5e;
    text-align: center;

    img {
      width: 80%;
    }
    .item {
    }
    .iconItem {
      width: 31%;
      position: relative;
      margin-bottom: 2.1875rem;
    }

    .line {
      position: absolute;
      bottom: 0;
      width: 1px;
      height: 1.875rem;
      background-color: #afafaf;
    }
    .shorts {
      width: 1px;
    }
  }
  .iconLists {
    margin: 0 auto 0;
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 0.6875rem;
    font-family: opposm, sans-serif;
    font-weight: 400;
    color: #4e4d5e;
    text-align: center;

    img {
      width: 80%;
    }
    .item {
    }
    .iconItems {
      width: 48%;
      position: relative;
      margin-bottom: 2.5rem;
    }

    .line {
      position: absolute;
      bottom: 0;
      width: 1px;
      height: 1.875rem;
      background-color: #afafaf;
    }
    .shorts {
      width: 1px;
      margin-left: 1px;
    }
  }
}

.forRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.3125rem;
  background: #3A1FFB;
  font-size:0.5rem;
  color: #ffffff;
}
</style>