import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Videojs from 'video.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import progressive from 'progressive-image/dist/vue'; // 渐进式
Vue.use(progressive, {
  removePreview: true,
  scale: true
})
// import style (>= Swiper 6.x)
import 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

Vue.use(ElementUI);
import 'vant/lib/index.css'; // Vant 全局样式引入
import './assets/font/font.css'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Videojs
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
