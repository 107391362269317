<template>
  <div class="gobalFoot">
    <div class="foot">
      <div style="width: 9.375rem; margin-top: 125px">
        <img src="../assets/gobalFoot/logo.svg" alt style="width: 10.3125rem" />
      </div>
      <div class="footCenter">
        <div class="name" @mouseover.stop="over" @mouseleave.stop="leave">
          快进时代（厦门）科技有限公司
        </div>

        <div class="phone" @mouseover.stop="over" @mouseleave.stop="leave">
          <div style="margin-top: 3px">
            <img
              src="../assets/gobalFoot/phone.svg"
              alt
              style="width: 18px; height: 18px"
            />
          </div>
          <div style="margin-left: 5px">0592-5095708</div>
        </div>
        <div class="letter" @mouseover.stop="over" @mouseleave.stop="leave">
          <div style="margin-top: 5px">
            <img
              src="../assets/gobalFoot/letter.svg"
              alt
              style="width: 18px; height: 18px"
            />
          </div>
          <div style="margin-left: 5px">service@ikuaijin.com</div>
        </div>
        <div class="Official">
          <el-popover
            placement="top"
            width="150"
            trigger="hover"
            popper-class="my-popover"
          >
            <div class="view" slot="reference">
              <img src="../assets/gobalFoot/Official.svg" />
              <span>官方视频号</span>
              <div>微信扫一扫</div>
            </div>
            <img src="../assets/gobalFoot/Official.svg" />
          </el-popover>

          <el-popover
            placement="top"
            width="150"
            trigger="hover"
            popper-class="my-popover"
          >
            <div slot="reference" class="view">
              <img src="../assets/gobalFoot/wxvideo.svg" />
              <span>官方抖音号</span>
              <div>抖音扫一扫</div>
            </div>
            <img src="../assets/gobalFoot/wxvideo.svg" />
          </el-popover>
        </div>

        <div class="title">
          <span
            style="margin-right: 10px; cursor: pointer"
            @click="routeGo('/home')"
            :style="{ fontFamily: $route.path == '/Home' ? 'opposb' : '' }"
            >首页</span
          >
          <span>|</span>
          <span
            style="margin: 0 10px; cursor: pointer"
            @click="routeGo('/kjProduct')"
            :style="{ fontFamily: $route.path == '/kjProduct' ? 'opposb' : '' }"
            >门店改造</span
          >
          <span>|</span>
          <span
            style="margin: 0 10px; cursor: pointer"
            @click="routeGo('/cooperation')"
            :style="{
              fontFamily: $route.path == '/cooperation' ? 'opposb' : '',
            }"
            >渠道合作</span
          >
          <span>|</span>
          <span
            style="margin: 0 10px; cursor: pointer"
            @click="routeGo('/devplatform')"
            :style="{
              fontFamily: $route.path == '/devplatform' ? 'opposb' : '',
            }"
            >开放平台</span
          >
          <span>|</span>
          <span
            style="margin: 0 10px; cursor: pointer"
            @click="routeGo('/aboutUs')"
            :style="{ fontFamily: $route.path == '/aboutUs' ? 'opposb' : '' }"
            >关于我们</span
          >
        </div>
        <div
          style="
            font-size: 0.75rem;
            margin-top: 5px;
            display: flex;
            align-items: center;
          "
        >
          <span
            style="font-size: 0.75rem; color: #ffffff; text-decoration: none"
            >增值电信业务经营许可证 闽B2-20230828</span
          >
        </div>
        <div
          style="
            font-size: 0.75rem;
            margin-top: 5px;
            display: flex;
            align-items: center;
          "
        >
          <a
            style="font-size: 0.75rem; color: #ffffff; text-decoration: none"
            target="_blank"
            href="https://beian.miit.gov.cn/"
            >闽ICP备2021012124号-1</a
          >
          <a
            style="
              display: flex;
              align-items: center;
              margin-left: 0.4rem;
              font-size: 0.75rem;
              color: #ffffff;
              text-decoration: none;
            "
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035527"
          >
            <img
              src="@/assets/beian.png"
              style="width: 0.9rem; height: 0.9rem"
            />
            <span>闽公网安备 35020302035527号</span>
          </a>
        </div>
      </div>
      <div class="submit">
        <div class="cooperation">合作咨询</div>
        <div>
          <input
            type="text"
            v-model="form.name"
            class="username"
            style="height: 35px;"
            placeholder="您的姓名"
          />
        </div>
        <div>
          <input
            type="text"
            v-model="form.mobile"
            style="height: 35px"
            placeholder="您的电话"
          />
        </div>
        <div>
          <el-cascader
                class="localShow"
                placeholder="您的意向合作区域"
                :options="baseCitys"
                filterable
                :props="optionProps"
                v-model="form.locationCityArray"
                popper-class="popperClass"
              ></el-cascader>
        </div>
        <div>
          <textarea
            type="text"
            v-model="form.consult"
            style="height: 65px;margin-top: 13px; font-family: opposr, sans-serif;font-size: 0.875rem;color: black;"
            placeholder="您想咨询的内容"
          />
        </div>
        <div class="btn" @click="submit">提&nbsp;&nbsp;交</div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { submit,queryCityTree} from "../network/gobal";
export default {
  components: {},
  data() {
    return {
      form: { name: "", mobile: "", consult: "" ,locationCityArray:[],locationCity:""},
      optionProps: {
        label: 'name',
        value: 'name',
        children: "districts",
        },
      baseCitys: [],
    };
  },
  created(){
    this.getAllCity();
  },

  mounted() {
  },
  methods: {
    over() {
      document.oncontextmenu = function () {
        event.returnValue = true;
      };
    },
    leave() {
      document.oncontextmenu = function () {
        event.returnValue = false;
      };
    },
    routeGo(path) {
      if (this.$route.path == path) {
        scrollTo(0, 0);
      } else {
        this.$router.push(path);
      }
    },
    submit() {
      const ruleName =
        /^(?:[\u4e00-\u9fa5]+)(?:·[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/;
      const rulePhone =
        /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
      if (!this.form.name) {
        this.message("请输入您的姓名", "提醒");
      } else if (!ruleName.test(this.form.name)) {
        this.message("请输入正确的姓名", "提醒");
      } else if (!this.form.mobile) {
        this.message("请输入您的电话号码", "提醒");
      } else if (!rulePhone.test(this.form.mobile)) {
        this.message("请输入正确的电话号码", "提醒");
      } else if(!this.form.locationCityArray || this.form.locationCityArray.length === 0){
        this.message("请选择您的意向合作区域", "提醒");
      } 
      else if (!this.form.consult) {
        this.message("请输入您想咨询的内容", "提醒");
      } else if (this.form.consult.length > 250) {
        this.message("咨询的内容最长不能超过250个字符", "提醒");
      } else {
        //地区从数组转为字符串
        this.form.locationCity = this.form.locationCityArray.join('/');
        submit(this.form).then((res) => {
          if (res.code == 200) {
            this.message("快进商店工作人员将尽快与您取得联系。", "提交成功");
            this.form = {
              name: "",
              mobile: "",
              consult: "",
            };
          } else {
            this.message(res.msg, "提醒");
          }
        });
      }
    },
    message(val, title = "") {
      this.$alert(`<span style="font-size:16px">${val}</span>`, title, {
        confirmButtonText: "确定",
        showClose: false,
        customClass: "customClass",
        dangerouslyUseHTMLString: true,
        callback: (action) => {},
      });
    },

//获取地区树
    getAllCity() {
      queryCityTree().then((res) => {
        if (res.code == 200) {
          this.baseCitys = this.getTreeData(res.data)
        }
      })
    },


 //递归数据进行处理
    getTreeData(data) {
      for (var i = 0; i < data.length > 0; i++) {
        if ( data[i].districts == null || data[i].districts.length <= 0  ) {
          // districts若为空数组，则将districts设为undefined
          data[i].districts = undefined
        } else {
         // 如果 districts 的 name 与当前对象的 name 相同，跳过该子分支(不需要这个子分支，直接设为 undefined)
         if (data[i].districts[0].name === data[i].name) {
           data[i].districts = undefined; 
          } else {
           //districts若不为空数组，则继续 递归调用 本方法
           this.getTreeData(data[i].districts);
          }
          }
      }
      return data
    },
  },
};
</script>
<style lang="scss">
.my-popover {
  padding: 0px;

  img{
    vertical-align: bottom;
  }
}

.popperClass ::-webkit-scrollbar {
  width: 1px;
  height: 100%;
  background-color: #f5f5f5;
}
</style>
<style lang="scss" scoped>
::-webkit-input-placeholder {
  color: #cccccc;
  font-family: opposr, sans-serif;
  letter-spacing: 1px;
}


::v-deep .localShow .el-input__inner{
       overflow: hidden;
      padding: 1px 2px 1px 10px;
      border-radius: 3px;
      color: #181818;
      font: unset 1.125rem;
    }


input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
textarea {
  padding-top: 8px;
  padding-left: 10px;
  border-radius: 3px;
  resize: none;
  border: white;
  color: #181818;
}
input {
  border-radius: 3px;
  padding-left: 10px;
  color: #181818;
}

.gobalFoot {
  width: 100%;
  background: linear-gradient(-90deg, #1f10e0, #430eff);
  height: 460px;
  padding-bottom: 2.5rem;
  font-family: opposr, sans-serif;
  display: block;
  .foot {
    width: 61%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .footCenter {
      margin-top: 90px;
      font-family: opposm, sans-serif;
      color: #ffffff;

      .name {
        margin-top: -3px;
        padding-bottom: 15px;
        border-bottom: 1px solid white;
        font-size: 1.125rem;
        letter-spacing: 1px;
        -moz-user-select: text;
        -webkit-user-select: text;
        user-select: text;
      }
      .phone {
        margin-top: 20px;
        display: flex;
        align-items: center;
        -moz-user-select: text;
        -webkit-user-select: text;
        user-select: text;
        font-size: 1rem;
      }
      .letter {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        // border-bottom: 1px solid white;
        font-size: 1rem;
        margin-top: -5px;
        -moz-user-select: text;
        -webkit-user-select: text;
        user-select: text;
      }
      .Official {
        display: flex;
        align-items: center;
        border-bottom: 1px solid white;
        padding-bottom: 19px;

        .view {
          text-align: center;
          &:nth-child(1) {
            margin-right: 30px;
          }
          img {
            display: block;
            width: 4.4375rem;
            height: 4.4375rem;
            vertical-align: bottom;
          }
          span,div {
            margin: 0px auto;
            display: inline-block;
            margin-top: 0.4375rem;
            font-size: 0.875rem;
            color: #ffffff;
          }

          div{
            display: block;
            margin-top: 0;
          }
        }
      }
      .title {
        margin-top: 20px;
        font-size: 0.875rem;
      }
    }
  }
  .submit {
    margin-top: 89px;
    color: #ffffff;
    font-size: 1.125rem;
    .cooperation {
      font-size: 1.125rem;
      margin-bottom: 30px;
      font-family: opposm;
      padding-bottom: 14px;
      border-bottom: 1px solid white;
    }

    input {
      width: 25rem;
      margin: 10px 0;
      &:nth-child(1) {
        margin-top: 0px;
      }
    }

    .localShow{
      width: 100%;
      margin-bottom: 3px;
      padding-left: 0px;height: 35px;
      font-family: opposr, sans-serif;

      ::placeholder{
        color: #B1B1B1;
        font-size: 0.8rem;
         font-family: opposr,sans-serif;
         letter-spacing: 1px;
      }
    }

    textarea {
      width: 25rem;
      margin: 4px 0;
      margin-top: 0px;
    }
    input::-webkit-input-placeholder {
      color: #B1B1B1;
      font-size: 0.8rem;
    }
    textarea::-webkit-input-placeholder {
      color: #B1B1B1 !important;
      font-family: opposr, sans-serif;
      letter-spacing: 1px;
      font-size: 0.8rem;
    }
 
    .btn {
      height: 35px;
      line-height: 35px;
      text-align: center;
      background: #ff3162;
      margin-top: 7px;
      border-radius: 3px;
      font-size: 0.875rem;
      cursor: pointer;
    }
  }
}
</style>
